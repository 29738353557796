<template>
  <div>
   <!--
      <el-dialog
      title
      custom-class="dialog-wrap"
      :show-close="false"
      :visible.sync="isVisible"
    >
      <span class="dialog-body"
        >点击右上角···按钮，然后在弹出的菜单中点击"在浏览器打开"，即可安装</span
      >
      <span slot="footer" class="dialog-footer" @click="isVisible = false">
        <el-button type="primary" @click="isVisible = false">我知道了</el-button> 
        我知道了
      </span>
    </el-dialog>
     
     --->
    <div class="overWrp" v-show="isVisible" @click="isVisible=false">
      <img src="https://sc2.hexiaoxiang.com/web_static/unity/guide.png" />
    </div>

    <div class="wrap" v-show="!isVisible">
      <div class="article-top-tips" v-if="browserEnv.isWeiXin && !browserEnv.isIOS && false">
        <img class="article-top-tips-img" :src="configInfo.wx_tips_src" />
      </div>

      <div class="content">
        <div class="section-head">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/unity/head.png" alt="">
        </div>

        <div class="section-body">
          <img class="section-body-font-src" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/unity/font.png" alt="">
          <img class="section-body-btn-src" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/unity/btn.png" alt="" @click="downloadHandle">
        </div>

        <div class="section-foot">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/unity/bottom.png" alt="">
        </div>
      </div>
    </div>
    <div class="desktop-wrap">
      <div class="desktop-tag-1">
        <div class="desktop-tag-icon"></div>
      </div>
      <div class="desktop-tag-2"></div>
      <div class="desktop-left">
        <img class="desktop-left-src" src="https://sc2.hexiaoxiang.com/web_static/unity/desktop-app.png" alt="">
      </div>
      <div class="desktop-right">
        <img class="desktop-rt-logo" src="https://sc2.hexiaoxiang.com/web_static/unity/logo@2x.png" alt="">
        <img class="desktop-rt-font" src="https://sc2.hexiaoxiang.com/web_static/unity/font.png" alt="">
        <img class="desktop-rt-btn" src="https://sc2.hexiaoxiang.com/web_static/unity/desktop-btn.png" alt="" @click="downloadHandle">
      </div>
    </div>
  </div>
</template>
<script>

window._czc = []

export default {
  components: {},
  data() {
    return {
      configInfo: {},
      isVisible: false
    };
  },
  computed: {
    browserEnv() {
      return {
        isPhone: Boolean(
          /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
        ),
        isWeiXin: Boolean(navigator.userAgent.match(/MicroMessenger/gi)),
        isMiniProgram: Boolean(navigator.userAgent.match(/miniProgram/gi)),
        isAli: Boolean(navigator.userAgent.match(/AlipayClient/gi)),
        isAndroid: Boolean(navigator.userAgent.match(/android/gi)),
        isIPhone: Boolean(navigator.userAgent.match(/iphone|ipod/gi)),
        isIPad: Boolean(navigator.userAgent.match(/ipad|Macintosh/gi)),
        isIOS: Boolean(
          navigator.userAgent.match(/iphone|ipod|ipad|Macintosh/gi)
        )
      };
    }
  },
  created() {
  },
  methods: {
    downloadHandle () {
      if (this.browserEnv.isWeiXin) {
        this.isVisible = true;
      } else {
        if (this.browserEnv.isIOS && !this.browserEnv.isWeiXin) {
          window.open('https://apps.apple.com/cn/app/id1481527553')
        } else {
          window.open('https://sc2.hexiaoxiang.com/apk/ch/hxx_chinese_inside.apk');
        }
      }
    },
  }
};
</script>
<style lang="scss">
.minWidth {
  min-width: 0;
}
.overWrp {
  width: 100%;
  height: 100vh;
  position: relative;
  background:url('https://sc2.hexiaoxiang.com/web_static/unity/guide_bg.png');
  background-size:cover;
  img {
    position: absolute;
    width: 94%;
    right: 3%;
    top: 0;
  }
}

.wrap {
  .el-dialog {
    width: 80%;
  }
  .el-dialog__footer {
    border-top: 1px solid #eee;
  }
  .el-dialog__header {
    padding: 0;
  }
  .dialog-wrap {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    margin: 0 !important;
    border-radius: 15px;
  }
}
</style>
<style lang="scss" scoped>
.wrap {
  min-width: 100vw;
  min-height: 100vh;
  background: #FFE248;
}
.content {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  max-width: 768px;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  font-size: 16px;
}
.article-top-tips {
  position: relative;
  // height: 70px;
  padding-top: 18%;
}
.article-top-tips-img {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 768px;
  z-index: 9;
}
.dialog-footer {
  display: block;
  width: 100%;
  color: #1989fa;
  text-align: center;
}
.dialog-body {
  font-size: 16px;
}

.section-head img,
.section-foot img {
  display: block;
  width: 100%;
}

.section-head {
  position: fixed;
  max-width: 768px;
  left: 50%;
  width: 100%;
  top: 0;
  z-index: 9;
  transform: translateX(-50%);
}

.section-foot {
  position: fixed;
  max-width: 768px;
  left: 50%;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transform: translateX(-50%);
}

.section-body {
  position: fixed;
  max-width: 574px;
  width: 68%;
  background-color: #fff;
  top: 19%;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  border-radius: 20px;
  z-index: 19;
}

.section-body-font-src {
  width: 100%;
  display: block;
}

.section-body-btn-src {
  margin-top: 30px;
  width: 90%;
}

.desktop-wrap {
  display: none;
}
@media screen and (min-device-width: 1024px) and (min-device-height: 768px) {
  .wrap {
    display: none;
  }
  .desktop-wrap {
    width: 100vw;
    display: block;
    background: #FFE248;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
    }
  }

  .desktop-tag-1 {

  }

  .desktop-left {
    position: relative;
    flex: 1;
    max-width: 520px;
  }

  .desktop-left-src {
    width: 100%;
  }

  .desktop-right {
    position: relative;
    margin-right: 30px;
  }

  .desktop-rt-logo {
    width: 304px;
    margin-bottom: 20px;
  }
  .desktop-rt-font {
    width: 436px;
    margin-bottom: 60px;
  }

  .desktop-rt-btn {
    width: 436px;
  }

  .desktop-tag-1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 66%;
    width: 100%;
    background: url('https://sc2.hexiaoxiang.com/web_static/unity/desktop-bg-1.png') top center / 100% 100% no-repeat;
  }
  .desktop-tag-icon {
    position: absolute;
    width: 5vw;
    height: 5vw;
    right: 5%;
    top: 10%;
    background: url('https://sc2.hexiaoxiang.com/web_static/unity/icon-clock.png') top center / 100% no-repeat;
  }
  .desktop-tag-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 34%;
    width: 100%;
    background: url('https://sc2.hexiaoxiang.com/web_static/unity/desktop-bg-2.png')bottom center / 100% no-repeat;
  }
}
</style>
